/* table {
    width: 170vh;
    height: 100%;
    display:block;
    overflow-x: scroll;
    scroll-behavior: auto;
} */
/* th, td {
    min-width: 140px;
} */

.show-popup {
	position: absolute;
	left: 25%;
	top: 5%;
	width: 50%;
}

th,
td {
	min-width: 120px;
	max-width: 220px;
	width: 300px;
}
th {
	font-size: 14px;
	color: #3f5785;
}
table {
	font-size: 13px;
	display: block;
	overflow-x: scroll;
	scroll-behavior: auto;
}

.action-btn {
	cursor: pointer;
}

.large-button {
	width: 90vw !important;
	max-width: 150px !important;
	height: 38px !important;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	background-color: var(--miig-color-secondary) !important;
	font-size: 17px !important;
	color: #f6f6f9 !important;
	text-transform: none !important;
	font-weight: normal !important;
	box-shadow: none !important;
}
