/* @import url('https://fonts.googleapis.com/css2?family=Festive&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Festive&family=Open+Sans:ital@1&family=Roboto:wght@100;300;500&display=swap');
/* body {
  font-family: url
} */
body {
	margin: 0;
	/* font-family: 'Festive', cursive; */
	font-family: 'Open Sans', sans-serif;
	/* -webkit-font-smoothing: antialiased; */
	/* -moz-osx-font-smoothing: grayscale; */
}

@font-face {
	font-family: 'Festive', cursive;
	font-family: 'Open Sans', sans-serif;
	src: url('https://fonts.googleapis.com/css2?family=Festive&family=Open+Sans:ital@1&family=Roboto:wght@100;300;500&display=swap');
}

/* @font-face {
    font-family: 'BebasNeue';
    src: url('./fonts/BebasNeue-Regular.eot');
    src: url('./fonts/BebasNeue-Regular.eot?#iefix') format('embedded-opentype'),
         url('./fonts/BebasNeue-Regular.woff') format('woff'),
         url('./fonts/BebasNeue-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
} */
