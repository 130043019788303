body {
	max-width: 100vw !important;
	overflow-x: hidden !important;
}
*:-webkit-scrollbar {
	display: none;
}
* {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
.body {
	font-family: Cairo;
	font-style: normal;
	overflow-y: scroll;
	max-width: 100vw !important;
	overflow-x: hidden !important;
}

#root {
	--miig-color-primary: var(--miig-color-primary);
	--miig-color-secondary: #3f5785;
	--miig-color-tertiary: rgba(63, 87, 133, 0.5);
	--miig-color-danger: #eb445a;
	--miig-color-success: #29c467;
	--miig-color-warning: #e0ac08;
}

/* material - ui styles */

/* .MuiSelect-root {
	color: black !important;
	font-size: 16px !important;
	font-weight: 700 !important;
	font-family: Cairo !important;
}

input[id='standard-search'] {
	border: none !important;
	box-shadow: none !important;
	background: transparent !important;
}

.MuiInputLabel-animated {
	position: inherit !important;
	padding-bottom: 2px !important;
	font-family: Cairo !important;
} */

/* input field styles */
/* 
.input-label {
	font-size: 15px;
	color: var(--miig-color-secondary);
	opacity: 0.4;
}

.input-value {
	color: #000000;
	font-size: 17px;
}

.input-container {
	width: 100% !important;
	max-width: 414px !important;
} */

/* headers */

.header-big {
	font-weight: normal;
	font-size: 17px;
}

.header-big__blue {
	color: var(--miig-color-primary);
}

/* margin and paddings */
.color-light-green {
	color: #aad8b1;
}

.color-green {
	color: #41c755;
}
.color-blue {
	color: var(--miig-color-secondary);
}

.color-red {
	color: #ff453a;
}

.svg-current-status {
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	text-align: center;
}

.mar-top-10 {
	margin-top: 15px !important;
}

.mar-b-22 {
	margin-bottom: 22px;
}

.mar-b-40 {
	margin-bottom: 40px;
}

.mar-b-15 {
	margin-bottom: 15px;
}

.mar-b-6 {
	margin-bottom: 6px !important;
}

.mar-r-12 {
	margin-right: 12px;
}

.mar-b-2 {
	margin-bottom: 2px;
}

.mar-b-12 {
	margin-bottom: 12px;
}

.mar-r-20 {
	margin-right: 20px;
}

.mar-l-7 {
	margin-left: 7px;
}

.mar-r-10 {
	margin-right: 10px;
}

.mar-b-14 {
	margin-bottom: 14px;
}

.mar-b-4 {
	margin-bottom: 4px;
}

.transparent-header .header__logo-row {
	padding: 0 17px;
	justify-content: center;
}

.header__logo-row {
	padding: 0 17px;
}

.icon-menu {
	width: 25px;
	height: 3px;
	background: var(--miig-color-secondary);
	border-radius: 5px;
	transition: all 0.5s ease-in-out;
}

.icon-menu::before,
.icon-menu::after {
	content: '';
	position: absolute;
	width: 25px;
	height: 3px;
	background: var(--miig-color-secondary);
	border-radius: 5px;
	transition: all 0.5s ease-in-out;
}

.icon-menu::before {
	transform: translateY(-8px);
}

.icon-menu::after {
	transform: translateY(8px);
}

.menu-icon-container.menu-cross-container .icon-menu {
	transform: translateX(-50px);
	background-color: transparent;
	box-shadow: none;
}

.menu-icon-container.menu-cross-container .icon-menu::before {
	transform: rotate(45deg) translate(35px, -35px);
}

.menu-icon-container.menu-cross-container .icon-menu::after {
	transform: rotate(-45deg) translate(35px, 35px);
}

.blue-header .header-option-row__device-properties .MuiTab-root {
	min-width: calc(100vw / 6) !important;
	background: var(--miig-color-primary) !important;
}

.blue-header .MuiTab-root {
	text-transform: none !important;
	box-shadow: none !important;
	font-family: Cairo !important;
	font-size: 14px !important;
	background: var(--miig-color-primary) !important;
	font-weight: bold !important;
	color: white !important;
}

.blue-header .MuiPaper-elevation4 {
	box-shadow: none !important;
}

.blue-header .PrivateTabIndicator-colorPrimary-2 {
	background: transparent !important;
	border-radius: 40px !important;
}

.transparent-header .MuiPaper-elevation4 {
	box-shadow: none !important;
}

.transparent-header .MuiTab-root {
	text-transform: none !important;
	box-shadow: none !important;
	font-family: Cairo !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 18px !important;
	line-height: 34px !important;
	color: var(--miig-color-secondary) !important;
}

.device-switch {
	/* height: 12px !important; */
	/* width: 23px !important; */
	/* padding: 0 !important;
	margin: 0 !important; */
	/* display: inline-block !important; */
}

.MuiSwitch-thumb {
	/* height: 12px !important;
	width: 12px !important;
	border-radius: 100% !important; */
	background: #41c755 !important;
}

.MuiSwitch-track {
	/* width: 23px !important;
	height: 12px !important; */
	background: rgba(65, 199, 85, 0.5) !important;
	/* border-radius: 99px !important; */
}

/* .MuiSelect-select.MuiSelect-select {
	font-family: Cairo !important;
	font-style: normal !important;
	font-weight: bold !important;
	font-size: 12px !important;
	line-height: 22px !important;

	color: var(--miig-color-secondary) !important;
	border-bottom: none !important;
	box-shadow: none !;
	padding-right: 50px !important;
} */

.device-switch {
	margin-left: -12px !important;
}

.backdrop {
	position: fixed;
	z-index: 6;
	height: 100vh;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.5);
	display: block;
	top: 0;
	left: 0;
	transition: transform 0.2s;
}

/* new home style */
.home {
	max-width: 100vw;
	overflow-x: hidden !important;
	background-color: white !important;
}

.body {
	font-family: Cairo;
	font-style: normal;
	overflow-y: scroll;
	max-width: 100vw;
	overflow-x: hidden !important;
	background-color: white !important;
}

/* material - ui styles */

/* .MuiSelect-root {
	color: black !important;
	font-size: 16px !important;
	font-weight: 700 !important;
	font-family: Cairo !important;
} */

/* input[id='standard-search'] {
	border: none !important;
	box-shadow: none !important;
	background: transparent !important;
} */

.MuiInputLabel-animated {
	/* display: none !important; */
	position: inherit !important;
	padding-bottom: 2px !important;
	font-family: Cairo !important;
}

/* input field styles */

.input-label {
	font-size: 15px;
	color: var(--miig-color-secondary);
	opacity: 0.4;
}

.input-value {
	color: #000000;
	font-size: 17px;
}

.input-container {
	width: 100% !important;
	max-width: 414px !important;
}

/* headers */

.header-big {
	font-weight: normal;
	font-size: 17px;
}

.header-big__blue {
	color: var(--miig-color-primary);
}

/* margin and paddings */

.svg-title {
	font-family: Cairo;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	text-align: center;
	color: var(--miig-color-primary);
	line-height: 20px;
	margin-bottom: 9px;
}

.color-green {
	color: #41c755;
}

.color-red {
	color: #ff453a;
}

.svg-current-status {
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	text-align: center;
}

.device-channel-header {
	display: flex;
	width: 100%;
	padding: 11px;
	background: var(--miig-color-secondary);
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 1;
	margin-top: 10px;
	color: #ffffff;
}

.transparent-header .header__logo-row {
	padding: 0 17px;
	justify-content: center;
}

.header__logo-row {
	padding: 0 17px;
}

.mig-blue-title {
	display: flex;
	height: 37px;
	justify-content: center;
	align-items: center;
}

.icon-menu {
	width: 25px;
	height: 3px;
	background: var(--miig-color-secondary);
	border-radius: 5px;
	transition: all 0.5s ease-in-out;
}

.icon-menu::before,
.icon-menu::after {
	content: '';
	position: absolute;
	width: 25px;
	height: 3px;
	background: var(--miig-color-secondary);
	border-radius: 5px;
	transition: all 0.5s ease-in-out;
}

.icon-menu::before {
	transform: translateY(-8px);
}

.icon-menu::after {
	transform: translateY(8px);
}

.menu-icon-container.menu-cross-container .icon-menu {
	transform: translateX(-50px);
	background-color: transparent;
	box-shadow: none;
}

.menu-icon-container.menu-cross-container .icon-menu::before {
	transform: rotate(45deg) translate(35px, -35px);
}

.menu-icon-container.menu-cross-container .icon-menu::after {
	transform: rotate(-45deg) translate(35px, 35px);
}

.blue-header .header-option-row__device-properties .MuiTab-root {
	min-width: calc(100vw / 6) !important;
	background: var(--miig-color-primary) !important;
}

.blue-header .MuiTab-root {
	text-transform: none !important;
	box-shadow: none !important;
	font-family: Cairo !important;
	font-size: 14px !important;
	background: var(--miig-color-primary) !important;
	font-weight: bold !important;
	color: white !important;
}

.blue-header .MuiPaper-elevation4 {
	box-shadow: none !important;
}

.blue-header .PrivateTabIndicator-colorPrimary-2 {
	background: transparent !important;
	border-radius: 40px !important;
}

.transparent-header .MuiPaper-elevation4 {
	box-shadow: none !important;
}

.transparent-header .MuiTab-root {
	text-transform: none !important;
	box-shadow: none !important;
	font-family: Cairo !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 18px !important;
	line-height: 34px !important;
	color: var(--miig-color-secondary) !important;
}

.device-switch {
}

.MuiSwitch-thumb {
	background: #41c755 !important;
}

.MuiSwitch-track {
	background: rgba(65, 199, 85, 0.5) !important;
}

.device-switch {
	margin-left: -12px !important;
}

/* .MuiSelect-icon {
	display: none !important;
} */

.MuiInput-underline::after,
.MuiInput-underline::before {
	border-bottom: none !important;
	padding: 0 !important;
}

/* 
.MuiSelect-select.MuiSelect-select,
.MuiSelect-select.MuiSelect-select::after {
	font-family: Cairo !important;
	font-style: normal !important;
	font-weight: bold !important;
	font-size: 12px !important;
	line-height: 22px !important;

	color: var(--miig-color-secondary) !important;
	border-bottom: none !important;
	box-shadow: none !;
	padding-right: 50px !important;
	line-height: 0;
	width: 100vw;
	background-color: transparent !important;
}

.MuiInputBase-input {
	padding: 0 !important;
	height: inherit !important ;
	margin: 0 !important;
}*/

.down-arrow {
	display: inline-block;
	position: absolute;
	top: 50%;
	right: 20px;
	transform: translate(0%, -50%);
}

.MuiSlider-root {
	padding: 0 !important;
	margin: 0 !important;
}

input[id='standard-search-2'] {
	border: none !important;
	box-shadow: none !important;
	background: transparent !important;
	font-family: Cairo !important;
	font-style: normal !important;
	font-weight: bold !important;
	font-size: 12px !important;
	line-height: 22px !important;
	color: var(--miig-color-secondary) !important;
}

.icon-disabled {
	filter: opacity(10%);
	/* filter: grayscale(100%); */
}

.unSelected-tab .MuiTab-wrapper {
	color: rgba(255, 255, 255, 0.5);
}

.selected-tab .MuiTab-wrapper {
	color: #ffffff;
}

.login .MuiInput-underline::after,
.sign-up .MuiInput-underline::after,
.device-registration .MuiInput-underline::after {
	border-bottom: 2px solid #5192e7 !important;
}
.login .MuiInput-underline::before,
.sign-up .MuiInput-underline::before,
.device-registration .MuiInput-underline::before {
	border-bottom: 1px solid rgb(128, 123, 123) !important;
}

.bg-white {
	background-color: #ffffff;
}

.mar-t-6 {
	margin-top: 6px !important;
}

.MuiAccordionSummary-root {
	min-height: 35px !important;
}

.MuiAccordionSummary-root.Mui-expanded {
	min-height: 45px !important;
}

.show-more,
.show-less {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	color: #ffffff;
}

.MuiAccordionSummary-root.Mui-expanded .show-more {
	display: none;
}

.MuiAccordionSummary-root .show-less {
	display: none !important;
}

.MuiAccordionSummary-root.Mui-expanded .show-less {
	display: inline-block !important;
}

.device-properties-footer {
	display: flex;
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	padding-bottom: 7px;
	background-color: white;
	justify-content: space-evenly;
	padding-top: 7px;
	z-index: 2;
}

.MuiButton-root {
	border-radius: 0 !important;
}

.device-properties-button {
	width: 300px;
	height: 58px;
	background: var(--miig-color-primary);
	color: #f6f6f9;
	display: flex;
	justify-content: center;
	align-items: center;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
}

.bottom-sticky {
	width: 100vw;
	left: 0px;
	position: fixed;
	bottom: 10px;
	padding: 30px;
}

.updated-device-prop {
	background: rgba(81, 146, 231, 0.2);
}

.global-input-container {
	text-align: left;
}

.global-input-label {
	padding-left: 5px;
	font-weight: 600;
	text-transform: uppercase;
	color: var(--miig-color-secondary);
}

.global-input-text {
	min-width: 250px;
	border: 2px solid #3f5785;
	box-sizing: border-box;
	font-size: 14px;
	height: auto;
	padding: 5px 15px;
	border-radius: 0px;
	color: var(--miig-color-secondary);
	margin-bottom: 10px;
}

.global-input-text__props {
	border: 0px;
	background-color: transparent;
	box-shadow: none;
	padding: 0px;
	font-weight: 700;
	font-size: 12px;
	margin-bottom: 0px;
}

.device-information-block > div:nth-of-type(odd) > div {
	background-color: rgba(63, 87, 133, 0);
}

.device-information-block > div:nth-of-type(odd) > div:first-of-type {
	background-color: rgba(63, 87, 133, 0.05);
}

.device-information-block > div:nth-of-type(even) > div {
	background-color: rgba(63, 87, 133, 0.05);
}

.device-information-block > div:nth-of-type(even) > div:first-of-type {
	background-color: rgba(63, 87, 133, 0.1);
}

.device-information-value .MuiSlider-markLabel {
	top: 7px !important;
}

.device-props-slider-container {
	padding: 35px 20px !important;
}

.global-input-container .MuiInputBase-root {
	width: 100%;
	margin-bottom: 10px;
}

.global-input-container .MuiSelect-select:focus {
	/* background-color: #fff; */
}

.global-input-container .MuiInputBase-input {
	width: 100%;
	min-width: 250px;
	padding: 5px 15px;
	border: 2px solid #3f5785;
	box-sizing: border-box;
	font-size: 14px;
	height: auto;
	background-color: white;
	color: #3f5785;
	font-family: Cairo;
}

.global-input-container-select__props .MuiInputBase-input {
	padding: 0px;
}

.global-input-container-select__props .MuiInputBase-input.Mui-disabled {
	opacity: 0.5;
}

.global-input-text:focus {
	outline: none;
	box-shadow: none;
	border: 2px solid #3f5785;
}

.global-input-text__props:focus {
	border: 0px;
	background-color: transparent;
	box-shadow: none;
	padding: 0px;
	font-weight: 700;
	font-size: 12px;
	margin-bottom: 0px;
}

.error-message {
	color: #ff453a;
	text-align: left;
	margin-top: -7px;
}

.full-width {
	width: 100%;
}

.global-input-container .edit-icon {
	position: absolute;
	right: 4%;
	top: 50%;
	transform: translate(0%, -50%);
}

.global-input-container:hover .edit-icon {
	display: none;
}

/* Default Line Height */
.lh-d {
	line-height: 1;
}

.tt-u {
	text-transform: uppercase;
	letter-spacing: 1px;
}

.ta-c {
	text-align: center;
}

.ta-l {
	text-align: left;
}

.ta-r {
	text-align: right;
}

.ta-j {
	text-align: justify;
}

.mar-top-10 {
	margin-top: 15px !important;
}

.mar-b-22 {
	margin-bottom: 22px;
}

.mar-b-40 {
	margin-bottom: 40px;
}

.mar-b-10 {
	margin-bottom: 10px;
}

.mar-b-6 {
	margin-bottom: 6px !important;
}

.mar-r-12 {
	margin-right: 12px;
}

.mar-b-2 {
	margin-bottom: 2px;
}

.mar-b-12 {
	margin-bottom: 12px;
}

.mar-r-20 {
	margin-right: 20px;
}

.mar-l-7 {
	margin-left: 7px;
}

.mar-r-10 {
	margin-right: 10px;
}

.mar-b-14 {
	margin-bottom: 14px;
}

.mar-b-4 {
	margin-bottom: 4px;
}

.pl-sm {
	padding-left: 10px;
}
.p-xs {
	padding: 5px;
}
.pl-xs {
	padding-left: 5px;
}

.pl-md {
	padding-left: 15px;
}

.pl-lg {
	padding-left: 20px;
}

.pr-xs {
	padding-right: 5px;
}

.pr-sm {
	padding-right: 10px;
}

.pr-md {
	padding-right: 15px;
}

.pr-lg {
	padding-right: 20px;
}

.pb-xs {
	padding-bottom: 5px;
}

.pb-sm {
	padding-bottom: 10px;
}

.pb-md {
	padding-bottom: 15px;
}

.mb-xs {
	margin-bottom: 5px;
}

.mb-sm {
	margin-bottom: 10px;
}

.mb-md {
	margin-bottom: 15px;
}

.mb-lg {
	margin-bottom: 20px;
}

.mt-xs {
	margin-top: 5px;
}

.mt-sm {
	margin-top: 10px;
}

.mt-md {
	margin-top: 15px;
}

.mt-lg {
	margin-top: 20px;
}

.ml-xs {
	margin-left: 15px;
}

.ml-sm {
	margin-left: 10px;
}

.ml-xsm {
	margin-left: 7px;
}

.ml-md {
	margin-left: 15px;
}

.ml-lg {
	margin-left: 20px;
}

.mt-50 {
	margin-top: 50px;
}

.mt-100 {
	margin-top: 100px;
}

.pt-xs {
	padding-top: 5px;
}

.pt-sm {
	padding-top: 10px;
}

.pt-md {
	padding-top: 15px;
}

.pt-lg {
	padding-top: 20px;
}

.pt-xlg {
	padding-top: 25px;
}

.pl-xlg {
	padding-left: 25px;
}

.pl-xs {
	padding-left: 5px;
}

.pl-sm {
	padding-left: 10px;
}

.pl-md {
	padding-left: 15px;
}

.p-xs {
	padding: 5px;
}

.p-sm {
	padding: 10px;
}

.p-md {
	padding: 15px;
}

.p-lg {
	padding: 20px;
}

/* Updated Miig Blue Styles */

.error-message {
	font-weight: 600;
	letter-spacing: 1px;
}

.visually-hidden {
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
}
input.visually-hidden:focus + label {
	outline: thin dotted;
}
input.visually-hidden:focus-within + label {
	outline: thin dotted;
}

/* Mig App Styles */

.mig-app {
	--miig-color-primary: #900c3f !important;
	--miig-color-secondary: #581845 !important;
	--miig-color-success: #581845 !important;
}

.mig-hide {
	display: none !important;
}

.mig-text-lg .config-title {
	font-size: 14px !important;
	line-height: 1.3;
	letter-spacing: 2px;
	text-transform: uppercase;
}
.text-bold {
	font-weight: 200;
}
.mig-logo-text {
	font-weight: 700;
	font-size: 15px;
	text-transform: uppercase;
	letter-spacing: 5px;
	text-align: left;
	line-height: 1;
}

.mig-app .global-buddon-small {
	border: none;
	background-color: #581845;
	color: #fff;
	border-radius: 5px;
	text-transform: uppercase;
	font-size: 10px;
	letter-spacing: 2px;
	padding: 10px 15px 9px 15px;
}

.mig-app .global-buddon-small:disabled {
	background-color: #aaa;
}

.mig-app .device-channel-header {
	border-radius: 5px;
	text-transform: uppercase;
	font-size: 12px;
	letter-spacing: 1px;
}

.mig-app .dashboard .bg-white {
	background-color: #eee1e1 !important;
	border-radius: 5px;
}

.mig-app .global-input-text {
	border-radius: 5px;
	border: 2px solid #581845;
}

.mig-app .start-stop__button {
	border-radius: 5px;
	text-transform: uppercase;
	font-size: 10px;
	letter-spacing: 2px;
}

.mig-app .medium-button {
	border-radius: 5px;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-size: 12px;
}

.mig-app .modal-container {
	border-radius: 5px;
}

.mig-app .start-stop__erase-button {
	border-radius: 5px;
	text-transform: uppercase;
	font-size: 12px;
	letter-spacing: 2px;
	border: none;
	background-color: #581845;
	color: #fff;
}

.mig-app .header-connection-status {
	margin-top: 54px;
}

.mig-bold {
	font-weight: 700;
}

.mig-app .channel-number {
	width: 50px;
	border-radius: 5px;
}

.mig-app .channel-box {
	border: 2px solid #581845;
	width: 100%;
	border-radius: 5px;
}
.mig-app .channel-value {
	justify-content: left;
	padding-left: 10px;
}

.mig-app .device-properties-header {
	margin-top: 54px;
}

.mig-app .connection-animation,
.mig-app .connection-graphic {
	display: none;
}
.mig-app .modal-container .svg-title {
	margin-bottom: 0px;
	color: #581845;
}

.mig-app .global-input-text__props {
	border: 0px;
}

.mig-app .blue-header {
	width: 100vw;
}

/* //////////////////////////////////////////////////////// */

.modal--background {
	position: fixed;
	z-index: 0;
	height: 100vh;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.5);
	display: block;
	top: 0;
	left: 0;
	transition: transform 0.2s;
}
​ .modal--container {
	display: block;
	position: fixed;
	z-index: 999;
	background-color: white;
	width: 40vw;
	left: 5vw;
	transition: transform 0.2s;
	padding-bottom: 15px;
}
​ .modal--body {
	height: calc(100% - 70px);
	background-color: red;
}

.start-stop__button-green {
	background: rgba(65, 199, 85, 0.2);
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	color: #41c755;
	outline: none !important;
	width: 142px;
	height: 47px;
}

.start-stop__button-green:hover {
	background: rgba(17, 119, 32, 0.2);
}

.start-stop__button-red:disabled {
	background: #f5f6f8;
	color: #c3cbda;
	outline: none !important;
}

.start-stop__button-green:disabled {
	background: #f5f6f8;
	color: #c3cbda;
	outline: none !important;
}

.start-stop__button-red {
	background: rgba(255, 69, 58, 0.2);
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	color: #ff453a;
	outline: none !important;
	width: 142px;
	height: 47px;
}

.start-stop__button-red:hover {
	background: rgba(255, 14, 0, 0.2);
}

.start-stop__erase-button {
	display: flex;
	height: 58px;
	border: 2px solid #3f5785;
	box-sizing: border-box;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	color: var(--miig-color-secondary);
	width: 100%;
	justify-content: center;
	align-items: center;
	outline: none !important;
}

.start-stop__button-red-disabled {
	background: #f5f6f8;
	color: #c3cbda;
	outline: none !important;
}

.start-stop__button-green-disabled {
	background: #f5f6f8;
	color: #c3cbda;
	outline: none !important;
}

.start-stop__erase-button-disabled {
	background: #f5f6f8;
	color: #c3cbda;
	border: 2px solid #c3cbda;
	outline: none !important;
}

.MuiSelect-select.MuiSelect-select {
	padding-left: 8px;
}

.MuiPopover-paper {
	width: 200px !important;
}

.close-button {
	cursor: pointer !important;
}
.close-button:hover {
	color: red;
	cursor: pointer !important;
}
.search-bar {
	border: 1.5px solid #3f5785 !important;
	margin-bottom: 0px !important;
	border-radius: 0px !important;
	/* height: 30px; */
}

.login-button {
	max-width: 100% !important;
	cursor: pointer !important;
}

.error-para {
	color: #ff453a;
	text-align: left;
	margin-top: 2px;
	text-transform: capitalize;
}
