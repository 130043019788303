div,
span {
	border: 1px solid blac;
}

.sidebar {
	height: 100vh;
}

.dashboard-container {
	height: 100vh;
	background-color: #f5f6f8;
	max-width: 100% !important;
}

.dashboard-main {
	height: 84vh;
	margin-top: 10px;
}

.img-container {
	height: 60px;
}

.menu-bar {
	height: 79vh;
	color: #3f5785;
	font-size: 14px;
	margin-top: 12px;
}

.menu {
	height: 40px !important;
	margin-bottom: 20px;
}

.menu:hover {
	color: #3f5785;
	border-right: 2px solid #3f5785;
	cursor: pointer;
}

.menu-icons {
	/* margin-left: 9px; */
	margin-right: 20px;
	padding-bottom: 0px !important;
}

img {
	min-width: 21px;
}

.dashboard-header {
	height: 28px;
	color: #3f5785;
}

.start-btn {
	width: 109px;
	height: 30px;
	border: 1px solid #3f5785;
	border-radius: 5px;
	font-weight: bold;
	font-size: 14px;
	line-height: 30px;
}

.app-options {
	font-weight: bold;
	font-size: 14px;
	line-height: 30px;
	color: #3f5785;
	margin-left: 10px;
	margin-right: 10px;
}

.refresh-btn {
	border: 1px solid rgba(63, 87, 133, 0.5);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 5px;
}

.footer-time {
	font-weight: 600;
	font-size: 14px;
	line-height: 30px;
	color: #3f5785;
}

.main-box {
	background-color: #fff;
	height: 30vh !important;
	margin: 20px;
}

.arrow:hover {
	cursor: pointer;
}

.logout-btn {
	color: #3f5785;
}

@-webkit-keyframes colorchange {
	0% {
		width: 100px;
	}
	100% {
		width: 10px;
	}
}

@keyframes colorchange {
	0% {
		width: 100px;
	}
	100% {
		width: 10px;
	}
}

.box {
	width: 100px;
	height: 100px;
	border: 1px solid black;
}

.changing {
	-webkit-animation: colorchange 2s;
	animation: colorchange 2s;
}

.tab-list {
	/* margin: 2vh; */
	padding: 0;
	list-style: none;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
}

/* tab list  */
.tab-list::before {
	content: '';
	display: block;
	height: 2px;
	width: 33.333%;
	position: absolute;
	bottom: 0;
	background-color: #fff;
	-webkit-transition: 0.3s;
	transition: 0.3s;
}

.tab-item {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	text-align: center;
	-webkit-transition: 0.3s;
	transition: 0.3s;
	opacity: 0.5;
}

.tab-toggle {
	display: none;
}

/* .tab-content {
  display: none;
} */

.tab-toggle:nth-child(1):checked ~ .tab-list .tab-item:nth-child(1),
.tab-toggle:nth-child(2):checked ~ .tab-list .tab-item:nth-child(2),
.tab-toggle:nth-child(3):checked ~ .tab-list .tab-item:nth-child(3) {
	opacity: 1;
}

/* tab bar  */
.tab-toggle:nth-child(2):checked ~ .tab-list::before {
	-webkit-transform: translateX(100%);
	transform: translateX(100%);
}

.tab-toggle:nth-child(3):checked ~ .tab-list::before {
	-webkit-transform: translateX(200%);
	transform: translateX(200%);
}

.tab-toggle:nth-child(1):checked ~ .tab-container .tab-content:nth-child(1),
.tab-toggle:nth-child(2):checked ~ .tab-container .tab-content:nth-child(2),
.tab-toggle:nth-child(3):checked ~ .tab-container .tab-content:nth-child(3) {
	display: block;
}

.tab-trigger {
	display: block;
	/* padding: 10px 0; */
}

.tab-container {
	padding: 15px 30px;
	width: 100%;
}

.iframe-data {
	width: 100%;
	height: 480px;
}
/*# sourceMappingURL=dashboard.css.map */

.cust-container {
	overflow-y: auto;
	height: 60vh;
	width: 100px;
	scroll-behavior: auto;
	scrollbar-width: none;
}

.buttons {
	/* width: 140px; */
}

@import url('https://fonts.googleapis.com/css2?family=Festive&display=swap');
body {
	font-family: 'Festive', cursive;
}

.popup-buttons {
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 100%;
	margin-top: 20px;
}

.popup-buttons.popup-buttons > * {
	margin: 0px;
}

.popup-hidden {
	visibility: hidden;
}

.popup-modal {
	position: 'absolute';
	left: 50%;
	top: 5%;
	width: '50%';
}
.popup-header {
	text-align: center;
}

/* .popupbackgroud > * {
  margin: 10px !important;
} */

.gap-container {
	border: 2px solid #3f5785 !important;
	margin-bottom: 0px !important;
	width: 100% !important;
	min-width: 100% !important;
	max-width: 100% !important;
	border-radius: 0px !important;
}

.gap-container:active {
	width: 100px !important;
}

.cust-label {
	padding-top: 15px;
	font-weight: bold;
	color: #3f5785 !important;
}

.loader-center {
	width: calc(50% + 65vh);
	text-align: center;
	position: absolute;
}

.show-error {
	color: red;
	font-size: 13px;
}

.menu:hover > .check-border {
	border-right: 2px solid red;
}

.hover-border {
	border-right: 2px solid #fff;
}

.hover-border:hover {
	border-right: 2px solid #3f5785;
}

.active-tab {
	/* border-right: 2px solid #3f5785; */
	color: #3f5785;
}

.error-border {
	border: 2px solid red !important;
}

.create-unit {
	height: 50vh;
}

.miigo-logo {
	/* width: 100%;
	max-width: 50%; */
}
