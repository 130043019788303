@import url('https://fonts.googleapis.com/css2?family=Festive&family=Open+Sans:wght@300&family=Roboto:wght@100&display=swap');

/* * {
  font-family: 'Josefin Sans', cursive;
} */

* {
	margin: 0;
	padding: 0;
	/* font-family: 'Josefin Sans', cursive; */
	/* font-family: 'Roboto', sans-serif; */
	/* font-family: 'Festive', cursive; */
	font-family: 'Open Sans', sans-serif;
	/* font-family: 'Roboto', sans-serif; */
	font-style: normal;
	font-weight: normal;
}

:root {
	--font-color: #3f5785;
	/* --lable-color: #c1c1c1; */
	--global-margin: 5px;
}

.container {
	height: 100vh;
}

.login-form-container {
	min-width: none;
	max-width: 360px;
	margin: 0 auto;
}

.logo-container {
	height: 65px;
}

#big-img {
	width: 80%;
}

.login,
.signup {
	color: #3f5785;
	cursor: pointer;
}

.login:hover,
.login:active,
.login:visited,
.login:link,
.signup:hover,
.signup:active,
.signup:visited,
.signup:link {
	color: var(--font-color);
}

.login,
.signup {
	border: none;
}

.login-btn-container {
	margin-top: 40px;
}

.login-btn {
	background-color: var(--font-color);
	text-decoration: none;
	border-radius: 0px;
	font-size: 17px !important;
}

.login-btn:hover {
	background-color: var(--font-color);
}

label {
	color: var(--lable-color);
	height: 40px;
	font-size: 15px;
	line-height: 28px;
}

.forgot-pass {
	font-size: 13px;
	/* margin-top: 40px; */
}

.forgot-pass:hover {
	cursor: pointer;
}

.header {
	margin-bottom: var(--global-margin);
	font-size: 18px;
	line-height: 34px;
}

input,
input:focus,
textarea:focus,
select:focus {
	/* outline: none !important;
  border: none !important;
  background-color: #fff !important;
  resize: none !important;
  margin: 0 !important; */
}
::placeholder {
	color: #c1c1c1;
	opacity: 1; /* Firefox */
}
#signup {
	display: none;
}

a {
	color: #3f5785;
}

.active {
	border-bottom: 2px solid var(--font-color);
}

.signup-btn {
	width: 100%;
	background-color: var(--font-color);
	text-decoration: none;
	border-radius: 0px;
	font-size: 17px !important;
}

.signup-btn:hover {
	background-color: var(--font-color);
}

.signup-btn-container {
	margin-top: 30px;
}

.signup-input-text {
	height: 30px;
}

.signup-input-text:active {
	outline: none;
	border: none;
}
/*# sourceMappingURL=style.css.map */

.login-btn-disabled {
	cursor: not-allowed !important;
}
