.select-box {
	padding-right: 24px;
	border: 1px solid black;
	border-radius: 4px;
}

.delete-modal {
	background-color: white;
	display: block;
	position: fixed;
	z-index: 999;
	background: 'white';
	width: 40vw;
	left: 30vw;
	transition: transform 0.2s;
	padding-bottom: 15px;
	top: calc(100% / 3);
}

.close-window {
	cursor: pointer !important;
}
.close-window:hover {
	color: red;
	cursor: pointer !important;
}
.model-container-data {
	display: block;
	position: fixed;
	z-index: 999;
	background: white;
	width: 40vw;
	left: 30vw;
	transition: transform 0.2s;
	padding-bottom: 15px;
}
